const actionSeekSchema = [
  {
    type: 'number',
    name: 'seconds',
    label: 'Go To Time: (in seconds)',
    events: ['input'],
    help: 'Set the play point to seconds from start',
    min: '0',
    step: 0.1,
  },
]

const actionSeekValue = {
  type: 'action_seek',
  seconds: 0
}

export { actionSeekSchema, actionSeekValue }
