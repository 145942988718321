const actionShowSchema = [
  {
    type: 'select',
    label: 'Entrance Animation:',
    name: 'entrance_type',
    options: {
      none: 'None',
      scale_up: 'Scale Up',
      from_left: 'From left',
      from_right: 'From right',
      from_above: 'From above',
      from_below: 'From below',
      from_rear: 'From rear',
      from_front: 'From front',
      from_object_transform: 'From Another Object',
      from_origin: 'From Origin',
    },
    events: ['input'],
  },
  {
    type: 'number',
    name: 'duration',
    label: 'Duration (seconds):',
    min: '0',
    step: 0.2,
    vfeNumber: true,
    events: ['input'],
  },
  {
    type: 'select',
    label: 'Easing:',
    name: 'easing',
    options: {
      linear: 'Linear',
      sinein: 'Smooth Start',
      sineout: 'Smooth End',
      sineinout: 'Smooth Start & End',
      elasticin: 'Elastic Start',
      elasticout: 'Elastic End',
      elasticinout: 'Elastic Start & End',
      backin: 'Overshoot on Start',
      backout: 'Overshoot on End',
      backinout: 'Overshoot on Start & End',
      // linear: 'Linear',
      // sinein: 'SineIn',
      // sineout: 'SineOut',
      // sineinout: 'SineInOut',
      // quadin: 'QuadIn',
      // quadout: 'QuadOut',
      // quadinout: 'QuadInOut',
      // cubicin: 'CubicIn',
      // cubicout: 'CubicOut',
      // cubicinout: 'CubicInOut',
      // quartin: 'QuartIn',
      // quartout: 'QuartOut',
      // quartinout: 'QuartInOut',
      // quintin: 'QuintIn',
      // quintout: 'QuintOut',
      // quintinout: 'QuintInOut',
      // expoin: 'ExpoIn',
      // expoout: 'ExpoOut',
      // expoinout: 'ExpoInOut',
      // circin: 'CircIn',
      // circout: 'CircOut',
      // circinout: 'CircInOut',
      // backin: 'BackIn',
      // backout: 'BackOut',
      // backinout: 'BackInOut',
      // elasticin: 'ElasticIn',
      // elasticout: 'ElasticOut',
      // elasticinout: 'ElasticInOut',
      // bouncein: 'BounceIn',
      // bounceout: 'BounceOut',
      // bounceinout: 'BounceInOut',
    },
    events: ['input'],
  },
  // {
  //   type: 'number',
  //   name: 'distance',
  //   label: 'Distance (meters):',
  //   vfeNumber: true,
  //   events: ['input'],
  //   validation: 'required',
  //   min: '0',
  //   step: 0.5,
  // },

  // {
  //   type: 'number',
  //   name: 'scale_factor',
  //   label: 'Scale mutiplier (number):',
  //   min: '0',
  //   vfeNumber: true,
  //   events: ['input'],
  // },
  // {
  //   class: 'flex',
  //   type: 'checkbox',
  //   name: 'use_current_user_placement',
  //   label: 'Re-position object relative to the user when appearing',
  //   events: ['input'],
  // },
]

const actionShowValue = {
  type: 'action_show',
  entrance_type: 'scale_up',
  use_current_user_placement: false,
  distance: 5,
  duration: 3,
  scale_factor: 10,
  easing: 'backout',
}

export { actionShowSchema, actionShowValue }
